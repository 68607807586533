body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: #c2410c; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type=date] {
  padding: 11px;
  font: 14px -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Helvetica,
    Arial,
    sans-serif;
  border: 1px solid rgb(212, 212, 212);
  /* border-radius: 4px; */
  outline: none;
}

/* ::file-selector-button {
  background: #059669;
  border: none;
  border-radius: 8px;
  color: #fff;
  padding: 0.85em;
}

::file-selector-button:hover {
  background: #047857;
} */

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile+label {
  color: white;
  display: inline-block;
}

.password-icon {
  margin: 0em 1em 0em 1em;
  cursor: pointer;
}

.badge {
  position: absolute;
  right: -10px;
  bottom: -7px;
  background-color: red;
  min-width: 14px;
  height: 14px;
  border-radius: 14px;
  color: #fff;
  text-align: center;
  line-height: 14px;
  padding: 2px;
  font-size: 12px;
}

.badge-container {
  position: relative;
}

#menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}

.blur {
  backdrop-filter: blur(0.1em);
  top: 0;
  left: 0;
  position: fixed;
  z-index: 9;
  width: 100vw;
  height: 100vh;
}



.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  list-style: none;

}

.pagination .buttonPagination {
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
  width: 52px;
  cursor: pointer;
}

.pagination li {
  display: inline-block;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
  width: 52px;
  cursor: pointer;
}

.active {
  content: "";
  display: block;
  border-bottom: 0.3em solid rgb(101, 163, 13);

}

.checked {
  display: inline-block;
  color: rgb(101, 163, 13) !important;
  cursor: pointer;
}

.checked-active {
  display: block;
  cursor: pointer;
  content: "";
  border-bottom: 0.3em solid rgb(101, 163, 13);
}

.pagination .buttonPaginationDisabled {
  font-weight: bold;
  color: rgba(0, 0, 0, 0.25);
  cursor: no-drop;
}

.cardDomanda {
  box-shadow: 0 0 2px 0 rgb(145 158 171 / 24%), 0 16px 32px -4px rgb(37 37 37 / 49%) !important;
  cursor: pointer;
  transition: 0.2s;
}


.cardDomanda.showAnswerHoverEffect:hover {
  background-color: #b8b8b8 !important;
}